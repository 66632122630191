import React, { Fragment, Component } from "react";

class AboutNewBG extends Component {
  render() {
    return (
      <Fragment>
        <div id="aaronaboutbg" className="z-10 absolute bottom-0">
          <img
            src="./no_bg_IMG_8176_small.png"
            alt="Aaron Villanueva - About"
          />
        </div>
      </Fragment>
    );
  }
}

export default AboutNewBG;

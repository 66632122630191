import { Component, Fragment, ReactNode } from "react";
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconCloud } from "../../../shadcomponents/ui/icon-cloud";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./InfiniteScroll.css";
import { SparklesText } from "../../../shadcomponents/ui/sparkles-text";
import { AnimatedShinyText } from "../../../shadcomponents/ui/animated-shiny-text";

const slugs = [
  "typescript",
  "angular",
  "react",
  "maui",
  "html5",
  "css3",
  "nodedotjs",
  "express",
  "nextdotjs",
  "svelte",
  "ruby",
  "csharp",
  "prisma",
  "amazonaws",
  "postgresql",
  "firebase",
  "nginx",
  "vercel",
  "digitalocean",
  "openai",
  "jest",
  "cypress",
  "docker",
  "git",
  "jira",
  "github",
  "gitlab",
  "visualstudiocode",
  "androidstudio",
  "sonarqube",
  "figma",
];

SwiperCore.use([Autoplay]);

class InfiniteScroll extends Component {
  render(): ReactNode {
    const images = slugs.map(
      (slug) => `https://cdn.simpleicons.org/${slug}/${slug}`
    );

    return (
      <Fragment>
        <div className="absolute flex  items-center justify-center overflow-hidden rounded-lg xs:top-1/3 xs:left-1/2 xs:transform xs:-translate-x-1/2 xs:-translate-y-1/3  sm:top-28 lg:top-12 sm:left-auto sm:right-1 opacity-80 z-10">
          <IconCloud images={images} />
        </div>
        <div className="skewMain">
          {/*}
          <div className="bg-text-container">
            <div className="animate-text-deux">
              <span className="dualScroll">Continuous education&nbsp;</span>
              <span className="dualScroll">Web Updates&nbsp;</span>
              <span className="dualScroll">
                Unlimited amount of energy&nbsp;
              </span>
            </div>
            <div className="animate-text-deux left">
              <span className="dualScroll">Continuous education&nbsp;</span>
              <span className="dualScroll">Web Updates&nbsp;</span>
              <span className="dualScroll">
                Unlimited amount of energy&nbsp;
              </span>
            </div>
          </div>
          { */}

          <div className="container">
            <Swiper
              autoplay={{ delay: 10000, disableOnInteraction: false }}
              effect={"fade"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              loop={true}
              pagination={true}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper trioblog"
            >
              <SwiperSlide>
                <div className="col">
                  <SparklesText text="AI" />
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am doing now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    Jan 2024
                  </span>
                  <p>
                    I am working on AI development using{" "}
                    <AnimatedShinyText>OpenAI's GPT</AnimatedShinyText> models
                    and <AnimatedShinyText>Meta's Llama 2</AnimatedShinyText>,
                    supported by{" "}
                    <AnimatedShinyText>Hugging Face</AnimatedShinyText> tools.
                    This involves leveraging the Transformers library for model
                    loading, fine-tuning, and deployment, alongside datasets for
                    data preprocessing. Using{" "}
                    <AnimatedShinyText>PyTorch</AnimatedShinyText> for
                    fine-tuning, and OpenAI APIs for integration to my current
                    existing projects.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="col">
                  <SparklesText text="Shopify Custom App" />
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-cyan-500 text-white rounded">
                    What I am doing now?
                  </span>
                  <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded">
                    Aug 2024
                  </span>
                  <p>
                    Currently, developing{" "}
                    <AnimatedShinyText>Shopify</AnimatedShinyText> custom apps
                    for <AnimatedShinyText>Plus stores</AnimatedShinyText>,
                    focusing on advanced features like{" "}
                    <AnimatedShinyText>custom checkout</AnimatedShinyText>,{" "}
                    <AnimatedShinyText>APIs</AnimatedShinyText>, and{" "}
                    integrations. This involves using the{" "}
                    <AnimatedShinyText>Shopify Admin API</AnimatedShinyText> and{" "}
                    <AnimatedShinyText>GraphQL</AnimatedShinyText> for store
                    data, Shopify App Bridge for{" "}
                    <AnimatedShinyText>UI</AnimatedShinyText>,{" "}
                    <AnimatedShinyText>Polaris</AnimatedShinyText> for design,
                    and frameworks like{" "}
                    <AnimatedShinyText>Node.js</AnimatedShinyText> or{" "}
                    <AnimatedShinyText>React</AnimatedShinyText> for
                    development.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InfiniteScroll;

import React, { Component } from "react";
import AboutAjov from "./Pages/AboutAjov";
import ContactAjov from "./Pages/ContactAjov";
import { PortfolioAjov } from "./Pages/PortfolioAjov";
import SkillPage from "./Pages/SkillPage";
import Background from "./UI/Background";
import Footer from "./UI/Footer";
import Header from "./UI/Header";
import InfiniteScroll from "./UI/InfiniteScroll/InfiniteScroll";
import Loader from "./UI/Loader";
import Navigation from "./UI/Navigation";

type Props = {};

type State = {
  loading: boolean;
};

class MainPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500);
  }
  render() {
    const hash = window.location.hash;
    return this.state.loading ? (
      <Loader />
    ) : (
      <div className="App">
        <Header />
        <Navigation />
        <Background />
        <AboutAjov />
        <SkillPage />
        <PortfolioAjov hashedpage={hash} />
        <ContactAjov />
        <InfiniteScroll />
        <Footer />
      </div>
    );
  }
}

export default MainPage;
